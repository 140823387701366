import { ProtectedPage } from "../../components";
import { AppsForm } from "../../components/AppsForm";
import { ApplicationsService } from "../../services";

export const AppsRegisterFormPage = () => {
  const handleSubmit = async (form: any) => {
    await ApplicationsService.create(form);
  };

  return (
    <ProtectedPage
      title="Cadastrar App"
      breadcrumbs={[
        { name: "Apps", to: "/apps" },
        { name: "Cadastrar App", to: "/apps/new" },
      ]}
    >
      <AppsForm
        buttonName="Salvar"
        formTitle="Cadastrar App"
        handleSubmit={handleSubmit}
        resetFields={true}
        successMessage="App Cadastrado com Sucesso!"
      />
    </ProtectedPage>
  );
};
