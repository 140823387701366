import { ApplicationsService } from "../../services";
import { parseISO } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { NavLink } from "react-router-dom";
import { ProtectedPage, MFDateFormat } from "../../components";
import { spacing } from "@material-ui/system";
import React from "react";
import styled from "styled-components";

import {
  Box as MuiBox,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Box,
} from "@material-ui/core";

import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@material-ui/icons";

const Paper = styled(MuiPaper)(spacing);

const AdminUser = styled.div`
  align-items: center;
  display: flex;
`;

function EnhancedTable() {
  const [data, setData] = React.useState<any>();
  const [deleteId, setDeleteId] = React.useState<any>();
  const [name, setName] = React.useState<any>();
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRemove = async () => {
    await ApplicationsService.deleteById(deleteId);
    handleClose();
    const resList = await ApplicationsService.list();
    setData(resList.data);
  };

  const handleRemoveAction = (id: any, name: any) => {
    setOpen(true);
    setDeleteId(id);
    setName(name);
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteId(null);
  };

  React.useEffect(() => {
    (async () => {
      const resList = await ApplicationsService.list();
      console.log(resList);
      setData(resList.data);
    })();
  }, []);

  return (
    <div>
      <Paper>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle id="alert-dialog-title">{`Deseja excluir ${name}?`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Tem certeza que deseja excluir?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRemove} color="primary">
              Sim
            </Button>
            <Button onClick={handleClose} color="primary" autoFocus={true}>
              Não
            </Button>
          </DialogActions>
        </Dialog>

        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Capa</TableCell>
                <TableCell align="left">App</TableCell>
                <TableCell align="right">Data</TableCell>
                <TableCell align="right">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                ?.sort(function (a: any, b: any) {
                  if (a.name < b.name) {
                    return -1;
                  }
                  if (a.name > b.name) {
                    return 1;
                  }
                  return 0;
                })
                .map((row: any, index: number) => {
                  return (
                    <TableRow
                      hover={true}
                      key={`${row.id}-${index}`}
                      tabIndex={-1}
                    >
                      <TableCell component="th" scope="row">
                        <Box mt={2} style={{ display: "flex", justifyContent: "flex-start" }}>
                          <img
                            src={typeof row?.thumbnail === "string" ? row?.thumbnail : URL.createObjectURL(row?.thumbnail)}
                            alt="Capa do App"
                            style={{
                              width: "100px",
                              height: "auto",
                              borderRadius: "8px",
                            }}
                          />
                        </Box>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <AdminUser>
                          <Typography>
                            Titulo: {row.title}
                            <br />
                            Link: <a href={row.link} target="_blank" rel="noreferrer">{row.link}</a>
                          </Typography>
                        </AdminUser>
                      </TableCell>
                      <TableCell align="center">
                        <Typography>
                          <MFDateFormat date={row.createdAt} />
                          <br />
                          {row.updatedAt && (
                            <MFDateFormat date={row.createdAt} />
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          aria-label="edit"
                          component={NavLink}
                          exact={true}
                          to={`/apps/${row.id}/edit`}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          onClick={(id) => handleRemoveAction(row.id, row.name)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>{/* 
        <TablePagination
          rowsPerPageOptions={[6]}
          component="div"
          count={data?.meta?.totalItems}
          rowsPerPage={6}
          page={page}
          onChangePage={handleChangePage}
        /> */}
      </Paper>
    </div>
  );
}

export const AppsIndexPage = () => {
  return (
    <ProtectedPage
      title="Apps"
      breadcrumbs={[{ name: "Apps", to: "/apps" }]}
      action={
        <Button
          color="primary"
          component={NavLink}
          exact={true}
          startIcon={<AddIcon />}
          to="/apps/new"
          variant="contained"
        >
          Cadastrar
        </Button>
      }
    >
      <Grid container={true} spacing={6}>
        <Grid item={true} xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </ProtectedPage>
  );
};
