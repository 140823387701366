import { ApplicationsService } from "../../services";
import { InsurancesForm, ProtectedPage } from "../../components";
import { useParams } from "react-router-dom";
import React from "react";
import { AppsForm } from "../../components/AppsForm";

export const AppsEditFormPage = () => {
  const [data, setData] = React.useState([]);
  const { id } = useParams<{ id: any }>();

  React.useEffect(() => {
    (async () => {
      const resList = await ApplicationsService.getById(id);
      setData(resList.data);
    })();
  }, []);

  const handleSubmit = async (form: any) => {
    await ApplicationsService.update(id, form);
  };

  return (
    <ProtectedPage
      title="Editar Plano de Saúde"
      breadcrumbs={[
        { name: "App", to: "/apps" },
        { name: "Editar App", to: "/apps/:id/edit" },
      ]}
    >
      <AppsForm
        data={data}
        buttonName="Salvar"
        formTitle="Editar App"
        handleSubmit={handleSubmit}
        resetFields={false}
        successMessage="App Atualizado com Sucesso!"
      />
    </ProtectedPage>
  );
};
