import { Alert as MuiAlert } from "@material-ui/lab";
import { MFTextField } from "../components";
import { spacing, SpacingProps } from "@material-ui/system";
import React, { useState } from "react";
import styled from "styled-components";

import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Grid,
  Paper as MuiPaper,
  Typography,
} from "@material-ui/core";
import axios from "axios";

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)<ButtonPropstype>(spacing);
const Card = styled(MuiCard)(spacing);
const Paper = styled(MuiPaper)(spacing);

interface ButtonPropstype extends SpacingProps {
  component?: string;
}

interface InsurancesFormProps {
  buttonName: string;
  data?: any;
  formTitle: string;
  handleSubmit: any;
  resetFields: boolean;
  successMessage: string;
}

export const AppsForm: React.FC<InsurancesFormProps> = ({
  buttonName,
  data,
  formTitle,
  handleSubmit,
  resetFields,
  successMessage,
}) => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isSubmitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState({ sent: false });
  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");
  const [coverImage, setCoverImage] = useState<File | null>(null);
  const [fileInputKey, setFileInputKey] = useState(Date.now());

  React.useEffect(() => {
    setTitle(data?.title || "");
    setLink(data?.link || "");
    setCoverImage(data?.thumbnail || "");
  }, [data]);

  const resetForm = () => {
    setTitle("");
    setLink("");
    setCoverImage(null);
    setFileInputKey(Date.now());
  };

  const submitForm = async (event: any) => {
    event.preventDefault();

    const payload = {
      title,
      link,
      thumbnail: coverImage, // Pode ser enviado nulo
    };

    console.log("Enviando payload:", payload);

    setSubmitting(true);
    try {
      await handleSubmit(payload);
      if (resetFields) {
        resetForm();
      }
      setStatus({ sent: true });
      setSubmitting(false);
      setErrors({});
    } catch (err: any) {
      console.log(err);
      if (err?.response?.status === 400) {
        setErrors(err?.response?.data);
      }
      setStatus({ sent: false });
    }
  };

  const onTitleChange = (event: any) => setTitle(event.target.value);
  const onLinkChange = (event: any) => setLink(event.target.value);
  const onCoverChange = (event: any) => {
    if (event.target.files.length > 0) {
      let file = event.target.files[0];
      let fileReader = new FileReader();

      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        submitPictureForm(file);
      };
      setCoverImage(file);
    }
  };

  const submitPictureForm = async (file: any) => {
    let formData = new FormData();
    formData.append("fileToUpload", file);

    let endpoint = "https://files.minhafono.com.br/upload-pictures.php";
    axios
      .post(endpoint, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        const imageUrl: any = "https://files.minhafono.com.br/" + res.data;

        // Atualiza o estado com a URL gerada
        setCoverImage(imageUrl);
      });
  };

  const removeCover = () => {
    if (coverImage) {
      URL.revokeObjectURL(URL.createObjectURL(coverImage));
    }
    setCoverImage(null);
    setFileInputKey(Date.now());
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h4" gutterBottom>
          {formTitle}
        </Typography>
        {status.sent && (
          <Alert severity="success" my={3}>
            {successMessage}
          </Alert>
        )}
        {isSubmitting ? (
          <Box display="flex" justifyContent="center" my={6}>
            <CircularProgress />
          </Box>
        ) : (
          <Paper>
            <Grid
              container
              spacing={8}
              direction={"row"}
            >
              <Grid item xs={6}>
                <form onSubmit={submitForm}>
                  <MFTextField
                    error={!!errors?.title}
                    fullWidth
                    helperText={errors?.title}
                    label="Nome do App"
                    my={2}
                    name="name"
                    onChange={onTitleChange}
                    placeholder="Digite o nome do App"
                    value={title}
                    variant="outlined"
                  />

                  <MFTextField
                    error={!!errors?.link}
                    fullWidth
                    helperText={errors?.link}
                    label="Insira o link do app"
                    my={2}
                    name="name"
                    onChange={onLinkChange}
                    placeholder="Link do app"
                    value={link}
                    variant="outlined"
                  />

                  <Grid item>
                    <input
                      key={fileInputKey}
                      type="file"
                      accept="image/*"
                      onChange={onCoverChange}
                      style={{ display: "none" }}
                      id="upload-cover"
                    />
                    <label htmlFor="upload-cover">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                      >
                        Inserir Capa
                      </Button>
                    </label>
                  </Grid>{/* 
                  {coverImage && (
                    <Grid item>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={removeCover}
                      >
                        Excluir
                      </Button>
                    </Grid>
                  )} */}
                  <Button
                    onClick={submitForm}
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={4}
                  >
                    {buttonName}
                  </Button>
                </form>
              </Grid>

              <Grid item xs={6} >
                {coverImage && (
                  <Box mt={2} style={{ display: "flex", justifyContent: "flex-start" }}>
                    <img
                      src={typeof coverImage === "string" ? coverImage : URL.createObjectURL(coverImage)}
                      alt="Capa do App"
                      style={{
                        width: "300px",
                        height: "auto",
                        borderRadius: "8px",
                      }}
                    />
                  </Box>
                )}
              </Grid>


            </Grid>
          </Paper>
        )}
      </CardContent>
    </Card>
  );
};
