import { ProtectedPage } from "../../components";
import { PrintForm } from "../../components/PrintForm";
import { PrintAndPracticeService } from "../../services";

export const PrintRegisterFormPage = () => {
  const handleSubmit = async (form: any) => {
    await PrintAndPracticeService.create(form);
  };

  return (
    <ProtectedPage
      title="Cadastrar"
      breadcrumbs={[
        { name: "Imprimir", to: "/imprimir" },
        { name: "Cadastrar", to: "/imprimir/new" },
      ]}
    >
      <PrintForm
        buttonName="Salvar"
        formTitle="Cadastrar"
        handleSubmit={handleSubmit}
        resetFields={true}
        successMessage="Cadastrado com Sucesso!"
      />
    </ProtectedPage>
  );
};
