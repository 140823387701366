import { Alert as MuiAlert } from "@material-ui/lab";
import { MFTextField } from "../components";
import { spacing, SpacingProps } from "@material-ui/system";
import React, { useState } from "react";
import styled from "styled-components";
import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Grid,
  Paper as MuiPaper,
  Typography,
  Select,
} from "@material-ui/core";
import axios from "axios";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Chip, Stack } from "@mui/material";

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)<ButtonPropstype>(spacing);
const Card = styled(MuiCard)(spacing);
const Paper = styled(MuiPaper)(spacing);

interface ButtonPropstype extends SpacingProps {
  component?: string;
}

interface PrintFormProps {
  buttonName: string;
  data?: any;
  formTitle: string;
  handleSubmit: any;
  resetFields: boolean;
  successMessage: string;
}


interface StatusMthod {
  label: string;
  value: string;
}
interface ArrayObjectSelectState {
  selectedStatusMethod: StatusMthod | null;
}
const statusMthod: StatusMthod[] = [
  {
    label: "Público",
    value: "Público",
  },
  {
    label: "Privado",
    value: "Privado",
  },
];

export const PrintForm: React.FC<PrintFormProps> = ({
  buttonName,
  data,
  formTitle,
  handleSubmit,
  resetFields,
  successMessage,
}) => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isSubmitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState({ sent: false });
  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");
  const [coverImage, setCoverImage] = useState<string[]>([]);
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const [thumbnail, setThumbnail] = useState<string | null>(null);

  React.useEffect(() => {
    setTitle(data?.title || "");
    setCoverImage(data?.document || "");
    setThumbnail(data?.thumbnail || "");
  }, [data]);

  const resetForm = () => {
    setTitle("");
    setCoverImage(null);
    setFileInputKey(Date.now());
  };

  const submitForm = async (event: any) => {
    event.preventDefault();

    const payload = {
      title,
      status: "Público",
      document: coverImage,
      thumbnail,
    };

    console.log("Enviando payload:", payload);

    setSubmitting(true);
    try {
      await handleSubmit(payload);
      if (resetFields) {
        resetForm();
      }
      setStatus({ sent: true });
      setSubmitting(false);
      setErrors({});
    } catch (err: any) {
      console.log(err);
      if (err?.response?.status === 400) {
        setErrors(err?.response?.data);
      }
      setStatus({ sent: false });
    }
  };

  const onTitleChange = (event: any) => setTitle(event.target.value);
  const onLinkChange = (event: any) => setLink(event.target.value);
  const onCoverChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      Array.from(files).forEach(file => {
        let fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
          submitPictureForm(file);
        };
      });
    }
  };

  const submitPictureForm = async (file: File) => {
    let formData = new FormData();
    formData.append("fileToUpload", file);

    try {
      const res = await axios.post("https://files.minhafono.com.br/upload-pdf.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      const fileUrl = res.data?.file_data;
      console.log(fileUrl);
      if (fileUrl.name) {
        setCoverImage(prev => [...prev, fileUrl.name]);
      }
    } catch (err) {
      console.error("Erro ao enviar PDF:", err);
    }
  };

  const onThumbnailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        uploadThumbnail(file);
      };
    }
  };

  const uploadThumbnail = async (file: File) => {
    let formData = new FormData();
    formData.append("fileToUpload", file);

    try {
      const res = await axios.post("https://files.minhafono.com.br/upload-pictures.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      const fileData = res.data;

      if (fileData) {
        setThumbnail(`https://files.minhafono.com.br/${res.data}`);
      }
    } catch (err) {
      console.error("Erro ao fazer upload da thumbnail:", err);
    }
  };

  const removeCover = () => {
    if (coverImage) {
      URL.revokeObjectURL(URL.createObjectURL(coverImage));
    }
    setCoverImage(null);
    setFileInputKey(Date.now());
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h4" gutterBottom>
          {formTitle}
        </Typography>
        {status.sent && (
          <Alert severity="success" my={3}>
            {successMessage}
          </Alert>
        )}
        {isSubmitting ? (
          <Box display="flex" justifyContent="center" my={6}>
            <CircularProgress />
          </Box>
        ) : (
          <Paper>
            <Grid
              container
              spacing={8}
              direction={"row"}
            >
              <Grid item xs={6}>
                <form onSubmit={submitForm}>
                  <MFTextField
                    error={!!errors?.title}
                    fullWidth
                    helperText={errors?.title}
                    label="Nome"
                    my={2}
                    name="name"
                    onChange={onTitleChange}
                    placeholder="Digite o nome do App"
                    value={title}
                    variant="outlined"
                  />
                  <Grid item>
                    <input
                      key={fileInputKey}
                      type="file"
                      accept="application/pdf"
                      multiple
                      onChange={onCoverChange}
                      style={{ display: "none" }}
                      id="upload-cover"
                    />
                    <label htmlFor="upload-cover">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        startIcon={coverImage ? <CheckCircleIcon style={{ color: "#fff" }} /> : null}
                      >
                        {coverImage ? "PDF Inserido" : "Inserir PDF"}
                      </Button>
                    </label>
                  </Grid>
                  {/* ✅ Lista dos PDFs inseridos */}
                  {coverImage?.length > 0 && (
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" style={{ marginTop: 16 }}>
                        PDFs enviados:
                      </Typography>
                      <Stack direction="row" spacing={1}>
                        {coverImage.map((url, index) => (
                          <Chip href={`https://files.minhafono.com.br/archives/pdf/${url}`} component="a" label={`${url}`} color="primary" clickable target="_blank" />
                        ))}
                      </Stack>
                    </Grid>
                  )}
                  <Grid item style={{ marginTop: 24 }}>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={onThumbnailChange}
                      style={{ display: "none" }}
                      id="upload-thumbnail"
                    />
                    <label htmlFor="upload-thumbnail">
                      <Button
                        variant="contained"
                        color="secondary"
                        component="span"
                      >
                        {thumbnail ? "Thumbnail Enviada" : "Inserir Thumbnail"}
                      </Button>
                    </label>
                  </Grid>
                  <Button
                    onClick={submitForm}
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={4}
                  >
                    {buttonName}
                  </Button>
                </form>
              </Grid>
              <Grid item xs={6}>
                {thumbnail && (
                  <Grid item xs={12} style={{ marginTop: 16, textAlign: 'center' }}>
                    <Typography variant="subtitle1">Prévia da Thumbnail:</Typography>
                    <img src={thumbnail} alt="Thumbnail Preview" style={{ width: 400, borderRadius: 8, marginTop: 8 }} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Paper>
        )}
      </CardContent>
    </Card>
  );
}; 
