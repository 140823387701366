/* eslint-disable import/first */
import React from "react";

// Guards
import AuthGuard from "../components/AuthGuard";

// Auth components
import { SignInPage, SignOutPage } from "../pages/auth";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

// Protected routes
import {
  AdminDetailPage,
  AdminEditFormPage,
  AdminIndexPage,
  AdminRegisterFormPage,
  AttendencePlataformsEditFormPage,
  AttendencePlataformsIndexPage,
  AttendencePlataformsRegisterFormPage,
  AttendenceMethodsIndexPage,
  SpecialitiesEditFormPage,
  SpecialitiesIndexPage,
  SpecialitiesRegisterFormPage,
  HomePage,
  InsurancesIndexPage,
  ProfessionalIndexPage,
  ProfessionalRegisterFormPage,
  ProfessionalEditFormPage,
  InsurancesEditFormPage,
  ChangePasswordPage,
  InsurancesRegisterFormPage,
  AttendenceMethodsRegisterFormPage,
  AttendenceMethodsEditFormPage,
  RatingsIndexPage,
  VideosIndexPage,
  VideoRegisterFormPage,
  PlaylistRegisterFormPage,
  PlaylistsIndexPage,
} from "../pages/protected";
import {
  ListAlt,
  AccountBox,
  Computer,
  ExitToApp,
  LocalHospital,
  SupervisedUserCircle,
  PeopleAlt,
  VpnKey,
  YouTube,
  ContactMail,
  Book,
  PrintOutlined,
} from "@material-ui/icons";
import { PlaylistEditFormPage } from "../pages/protected/PlaylistEditFormPage";
import { EmailRegisterFormPage } from "../pages/protected/EmailRegisterFormPage";
import { EmailEditFormPage } from "../pages/protected/EmailEditFormPage";
import { EmailsIndexPage } from "../pages/protected/EmailIndexPage";
import { ProfessionalSuperIndexPage } from "../pages/protected/ProfessionalSuperIndexPage";
import { EbooksIndexPage } from "../pages/protected/EbooksIndexPage";
import { AppsIndexPage } from "../pages/protected/AppsIndexPage";
import { AppsEditFormPage } from "../pages/protected/AppsEditFormPage";
import { AppsRegisterFormPage } from "../pages/protected/AppsRegisterForm";
import { PrintIndexPage } from "../pages/protected/PrintIndexPage";
import { PrintEditFormPage } from "../pages/protected/PrintEditFormPage";
import { PrintRegisterFormPage } from "../pages/protected/PrintRegisterFormPage";
import { EbooksRegisterFormPage } from "../pages/protected/EbooksEditFormPage";
import { ProfessionalIndexIncompletePage } from "../pages/protected/ProfessionalIndexIncompletePage";
import { Apps } from "@mui/icons-material";

// Routes using the Auth layout
export const authRoutes = [
  {
    id: "auth-sign-in",
    path: "/auth/sign-in",
    component: SignInPage,
    children: null,
  },
  {
    id: "auth-sign-out",
    path: "/auth/sign-out",
    component: SignOutPage,
    children: null,
  },
  {
    id: "auth-404",
    path: "/auth/404",
    component: Page404,
    children: null,
  },
  {
    id: "auth-500",
    path: "/auth/500",
    component: Page500,
    children: null,
  },
];

// Routes that are protected

export const protectedRoutes = [
  {
    id: "home",
    path: "/",
    component: HomePage,
    guard: AuthGuard,
    children: null,
  },

  // beggining of admin section
  {
    id: "admin",
    path: "/admin-users",
    component: AdminIndexPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "admin-new",
    path: "/admin-users/new",
    component: AdminRegisterFormPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "admin-edit",
    path: "/admin-users/:id/edit",
    component: AdminEditFormPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "admin-detail",
    path: "/admin-users/:id/admin-detail",
    component: AdminDetailPage,
    guard: AuthGuard,
    children: null,
  },
  // end of admin section

  // beggining of specialities section
  {
    id: "specialities",
    path: "/specialities",
    component: SpecialitiesIndexPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "specialities-new",
    path: "/specialities/new",
    component: SpecialitiesRegisterFormPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "specialities-edit",
    path: "/specialities/:id/edit",
    component: SpecialitiesEditFormPage,
    guard: AuthGuard,
    children: null,
  },

  // end of specialities section

  // beggining of attendence-plataforms section
  {
    id: "attendence-plataforms",
    path: "/attendence-plataforms",
    component: AttendencePlataformsIndexPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "attendence-plataforms-new",
    path: "/attendence-plataforms/new",
    component: AttendencePlataformsRegisterFormPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "attendence-plataforms-edit",
    path: "/attendence-plataforms/:id/edit",
    component: AttendencePlataformsEditFormPage,
    guard: AuthGuard,
    children: null,
  },
  // end of attendence-plataforms section

  // begginig of attendence methods section

  {
    id: "attendence-methods",
    path: "/attendence-methods",
    component: AttendenceMethodsIndexPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "attendenceMethodsRegisterFormPage",
    path: "/attendence-methods/new",
    component: AttendenceMethodsRegisterFormPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "attendenceMethodsEditFormPage",
    path: "/attendence-methods/:id/edit",
    component: AttendenceMethodsEditFormPage,
    guard: AuthGuard,
    children: null,
  },
  // beggining of professionals section
  {
    id: "professionals",
    path: "/professionals",
    component: ProfessionalIndexPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "professionals-incomplete",
    path: "/professionals-incomplete",
    component: ProfessionalIndexIncompletePage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "supervisores",
    path: "/supervisores",
    component: ProfessionalSuperIndexPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "professionals-new",
    path: "/professionals/new",
    component: ProfessionalRegisterFormPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "supervisores-new",
    path: "/supervisores/new",
    component: ProfessionalRegisterFormPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "professionals",
    path: "/professionals/:id/edit",
    component: ProfessionalEditFormPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "supervisores",
    path: "/supervisores/:id/edit",
    component: ProfessionalEditFormPage,
    guard: AuthGuard,
    children: null,
  },
  // beggining of professionals section

  // beggining of insurances section
  {
    id: "insurances",
    path: "/insurances",
    component: InsurancesIndexPage,
    guard: AuthGuard,
    children: null,
  },

  {
    id: "insurances",
    path: "/insurances/new",
    component: InsurancesRegisterFormPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "insurances",
    path: "/insurances/:id/edit",
    component: InsurancesEditFormPage,
    guard: AuthGuard,
    children: null,
  },
  // end of insurances section

  {
    id: "change-password",
    path: "/change-password",
    component: ChangePasswordPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "ratings",
    path: "/ratings",
    component: RatingsIndexPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "videos",
    path: "/videos",
    component: VideosIndexPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "videos",
    path: "/videos/:id/edit",
    component: VideoRegisterFormPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "videos-new",
    path: "/videos/new",
    component: VideoRegisterFormPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "apps",
    path: "/apps",
    component: AppsIndexPage,
    guard: AuthGuard,
    children: null,
  },

  {
    id: "apps",
    path: "/apps/new",
    component: AppsRegisterFormPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "apps",
    path: "/apps/:id/edit",
    component: AppsEditFormPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "print",
    path: "/imprimir",
    component: PrintIndexPage,
    guard: AuthGuard,
    children: null,
  },

  {
    id: "print",
    path: "/imprimir/new",
    component: PrintRegisterFormPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "print",
    path: "/imprimir/:id/edit",
    component: PrintEditFormPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "ebooks",
    path: "/ebooks",
    component: EbooksIndexPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "ebooks",
    path: "/ebooks/:id/edit",
    component: EbooksRegisterFormPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "ebooks-new",
    path: "/ebooks/new",
    component: EbooksRegisterFormPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "playlist-new",
    path: "/videos/playlists/new",
    component: PlaylistRegisterFormPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "playlists",
    path: "/videos/playlists",
    component: PlaylistsIndexPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "playlist",
    path: "/videos/playlists/:id/edit",
    component: PlaylistEditFormPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "mailing-new",
    path: "/mailing/new",
    component: EmailRegisterFormPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "playlist",
    path: "/mailing/:id/edit",
    component: EmailEditFormPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "mailing",
    path: "/mailing",
    component: EmailsIndexPage,
    guard: AuthGuard,
    children: null,
  },
];

// Routes visible in the sidebar
export const sidebarRoutes = [
  {
    id: "Profissionais",
    path: "/professionals",
    icon: <AccountBox />,
    component: null,
    children: null,
    header: "Minha Fono",
  },
  {
    id: "Supervisores",
    path: "/supervisores",
    icon: <AccountBox />,
    component: null,
    children: null,
  },
  {
    id: "Especialidades",
    path: "/specialities",
    icon: <ListAlt />,
    component: null,
    children: null,
  },

  {
    id: "Plataformas de Atendimento",
    path: "/attendence-plataforms",
    icon: <Computer />,
    component: null,
    children: null,
  },

  {
    id: "Métodos de Atendimento",
    path: "/attendence-methods",
    icon: <PeopleAlt />,
    component: null,
    children: null,
  },

  {
    id: "Planos de Saúde",
    path: "/insurances",
    icon: <LocalHospital />,
    component: null,
    children: null,
  },
  {
    id: "Comentário",
    path: "/ratings",
    icon: <LocalHospital />,
    component: null,
    children: null,
  },
  {
    id: "Videos",
    path: "/videos",
    icon: <YouTube />,
    component: null,
    children: null,
  },
  {
    id: "Apps",
    path: "/apps",
    icon: <Apps />,
    component: null,
    children: null,
  },
  {
    id: "E-books",
    path: "/ebooks",
    icon: <Book />,
    component: null,
    children: null,
  },
  {
    id: "Imprimir e praticar",
    path: "/imprimir",
    icon: <PrintOutlined />,
    component: null,
    children: null,
  },
  {
    id: "Email",
    path: "/mailing",
    icon: <ContactMail />,
    component: null,
    children: null,
    header: "Mailing",
  },
  {
    id: "Administradores",
    path: "/admin-users",
    icon: <SupervisedUserCircle />,
    children: null,
    component: null,
    header: "Administrativo",
  },
  {
    id: "Alterar Senha",
    path: "/change-password",
    icon: <VpnKey />,
    children: null,
    component: null,
    header: "Meu Acesso",
  },
  {
    id: "Sair",
    path: "/auth/sign-out",
    icon: <ExitToApp />,
    children: null,
    component: null,
  },
];
