import { NavLink } from "react-router-dom";
import React from "react";
import styled from "styled-components";
import {
  Avatar as MuiAvatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
  TextField,
  Chip,
} from "@material-ui/core";

import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@material-ui/icons";
import { ProtectedPage, MFDateFormat } from "../../components";
import { ProfessionalsService } from "../../services";

import { spacing } from "@material-ui/system";
import moment from "moment";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Swal from "sweetalert2";
//import { CSVLink } from 'react-csv';
import * as XLSX from "xlsx";

const Paper = styled(MuiPaper)(spacing);

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
  margin-right: 16px;
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(4),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    textField: {
      margin: theme.spacing(1),
      minWidth: 140,
    },
  })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Área",
  "Data de cadastro (Cresc)",
  "Data de cadastro (Decresc)",
  "Data de término",
  "Ordem alfabética",
];
let Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timerProgressBar: true,
  didOpen: async (toast) => {},
  willClose: async () => {},
});
function EnhancedTable() {
  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState<any>({});
  const [dataExport, setDataExport] = React.useState<any>({});
  const [deleteId, setDeleteId] = React.useState<any>();
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState<any>();
  const [search, setSearch] = React.useState<any>("%");
  const [personName, setPersonName] = React.useState([]);
  const [active, setActive] = React.useState();
  const [csvExport, setCsvExport] = React.useState([]);
  const [filter, setFilter] = React.useState([]);

  const handleChange = async (event: any) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );

    Toast.fire({
      icon: "info",
      title: "Carregando...",
    });
    console.log(value);
    setFilter(value);
    const resList = await ProfessionalsService.list(
      page + 1,
      20,
      search,
      value,
      false,
      active
    );

    setData(resList.data);
  };

const exportUsersToExcel = async (status) => {
  try {
    Swal.fire({
      icon: "info",
      title: "Carregando usuários...",
      toast: true,
      position: "bottom-end",
      showConfirmButton: false,
      timerProgressBar: true,
    });

    const response = await ProfessionalsService.list(
      1,
      1000000,
      "%",
      [],
      false,
      status !== "Todos" ? status === "Ativo" : undefined
    );

    if (response.status !== 200) throw new Error("Erro ao carregar os dados.");

    const usersData = response.data.results.map((row) => [
      moment(row.createdAt).format("DD/MM/YYYY HH:mm:ss"),
      row.professionalsPlans?.[0]?.date_due
        ? moment(row.professionalsPlans[0].date_due).format(
            "DD/MM/YYYY HH:mm:ss"
          )
        : "-",
      row.id,
      row.picture || "-",
      row.name,
      row.formation || "-",
      row.email || "-",
      row.whatsapp || "-",
      row.cpf_cnpj || "-",
      row.title || "-",
      row.clinicAddresses?.[0]?.city || "-",
      row.clinicAddresses?.[0]?.state || "-",
      row.clinicAddresses?.[0]?.street || "-",
      row.clinicAddresses?.[0]?.district || "-",
      row.clinicAddresses?.[0]?.zipcode || "-",
      row.status || "-",
    ]);

    const worksheet = XLSX.utils.aoa_to_sheet([
      [
        "Data de Criação",
        "Data de Vencimento do Plano",
        "ID",
        "Imagem",
        "Nome",
        "Formação",
        "E-mail",
        "WhatsApp",
        "CPF/CNPJ",
        "Título",
        "Cidade",
        "Estado",
        "Endereço",
        "Bairro",
        "CEP",
        "Status do Plano",
      ],
      ...usersData,
    ]);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Usuários");
    XLSX.writeFile(workbook, `usuarios-${status.toLowerCase()}.xlsx`);

    Swal.fire({
      icon: "success",
      title: "Arquivo gerado com sucesso!",
      toast: true,
      position: "bottom-end",
      showConfirmButton: false,
      timer: 3000,
    });
  } catch (error) {
    console.error(error);
    Swal.fire({
      icon: "error",
      title: "Erro ao gerar o arquivo",
      text: error.message || "Falha ao carregar os dados.",
    });
  }
};


  const handleChangeStatus = async (event: any) => {
    const newStatus = event.target.value;
    setActive(newStatus);

    Toast.fire({
      icon: "info",
      title: "Carregando...",
    });

    async function fetchData() {
      try {
        console.log("foi");
        const resList = await ProfessionalsService.list(
          page + 1,
          20,
          search,
          filter,
          false,
          newStatus
        );

        if (resList.status === 200) {
          setData(resList.data);
        }

        setCsvExport([]);

        const newData = [];
        // Formatar dados e adicionar ao newData
        newData.push([
          "Data de Criação",
          "Data de Vencimento do Plano",
          "ID",
          "Imagem",
          "Nome",
          "Formação",
          "E-mail",
          "WhatsApp",
          "CPF/CNPJ",
          "Título",
          "Cidade",
          "Estado",
          "Endereço",
          "Bairro",
          "CEP",
          "Status do Plano",
        ]);
        const resListAll = await ProfessionalsService.list(
          page + 1,
          260,
          search,
          filter,
          false,
          newStatus
        );

        resListAll.data.results.forEach((row) => {
          newData.push([
            moment(row.createdAt).format("DD/MM/YYYY HH:mm:ss").toString(),
            moment(row?.professionalsPlans[0]?.date_due)
              .format("DD/MM/YYYY HH:mm:ss")
              .toString(),
            row?.id,
            row?.picture,
            row?.name,
            row?.formation || "-",
            row?.email,
            row?.whatsapp,
            row?.cpf_cnpj,
            row?.title,
            row?.clinicAddresses?.[0]?.city || "-",
            row?.clinicAddresses?.[0]?.state || "-",
            row?.clinicAddresses?.[0]?.street || "-",
            row?.clinicAddresses?.[0]?.district || "-",
            row?.clinicAddresses?.[0]?.zipcode || "-",
            "Ativo",
          ]);
        });
        console.log(newData);
        setCsvExport(newData);
      } catch (error) {
        console.error(error);
      }
    }

    async function fetchDataIncompleto() {
      console.log("incompleee");
      try {
        const resList = await ProfessionalsService.listIncomplete(
          page + 1,
          20,
          search,
          filter,
          false,
          newStatus
        );
        console.log(resList);
        if (resList.status === 200) {
          setData(resList.data);
          Toast.close();
        }

        setCsvExport([]);

        const newData = [];
        // Formatar dados e adicionar ao newData
        newData.push([
          "Data de Criação",
          "ID",
          "Imagem",
          "Nome",
          "Formação",
          "E-mail",
          "WhatsApp",
          "CPF/CNPJ",
          "Título",
          "Cidade",
          "Estado",
          "Endereço",
          "Bairro",
          "CEP",
          "Status do Plano",
        ]);
        const resListAll = await ProfessionalsService.listIncomplete(
          page + 1,
          1000,
          search,
          filter,
          false,
          newStatus
        );

        resListAll.data.results.forEach((row) => {
          newData.push([
            moment(row.createdAt).format("DD/MM/YYYY HH:mm:ss").toString(),
            row?.id,
            row?.picture,
            row?.name,
            row?.formation || "-",
            row?.email,
            row?.whatsapp,
            row?.cpf_cnpj,
            row?.title,
            row?.clinicAddresses?.[0]?.city || "-",
            row?.clinicAddresses?.[0]?.state || "-",
            "-",
            "-",
            "-",
            "Inativo",
          ]);
        });
        console.log(newData);
        setCsvExport(newData);
      } catch (error) {
        console.error(error);
      }
    }

    async function fetchDataAll() {
      try {
        const resList = await ProfessionalsService.list(
          page + 1,
          20,
          search,
          filter,
          false,
          newStatus
        );

        const resListAll = await ProfessionalsService.list(
          page + 1,
          260,
          search,
          filter,
          false,
          newStatus
        );

        if (resList.status === 200) {
          setData(resList.data);
        }

        setCsvExport([]);

        let newData: any = [];
        // Formatar dados e adicionar ao newData
        newData.push([
          "Data de Criação",
          "ID",
          "Imagem",
          "Nome",
          "Formação",
          "E-mail",
          "WhatsApp",
          "CPF/CNPJ",
          "Título",
          "Cidade",
          "Estado",
          "Endereço",
          "CEP",
          "Status do Plano",
        ]);

        resListAll.data.results.forEach((row) => {
          newData.push([
            moment(row.createdAt).format("DD/MM/YYYY HH:mm:ss").toString(),
            row?.id,
            row?.picture,
            row?.name,
            row?.formation || "-",
            row?.email,
            row?.whatsapp,
            row?.cpf_cnpj,
            row?.title,
            row?.clinicAddresses?.[0]?.city || "-",
            row?.clinicAddresses?.[0]?.state || "-",
            row?.clinicAddresses?.[0]?.street || "-",
            row?.clinicAddresses?.[0]?.zipcode || "-",
            row?.status,
          ]);
        });

        setCsvExport(newData);
        Toast?.close();
      } catch (error) {
        console.error(error);
      }
    }

    switch (event.target.value) {
      case "true":
        fetchData();
        break;
      case "false":
        fetchDataIncompleto();
        break;
      case "findAll":
        fetchDataAll();
        break;
      default:
      //fetchDataAll();
    }
  };

  const handleChangePage = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    const resList = await ProfessionalsService.list(
      newPage + 1,
      20,
      search,
      filter,
      false,
      active
    );
    setData(resList.data);
  };
  const goToLastPage = async () => {
    const lastPage = Math.ceil(data.totalResults / 20) - 1;
    await handleChangePage(null, lastPage);
  };

  const handleRemove = async () => {
    await ProfessionalsService.deleteById(deleteId);
    handleClose();
    const resList = await ProfessionalsService.list(
      page + 1,
      20,
      search,
      filter,
      false,
      active
    );
    setData(resList.data);
  };

  const handleRemoveAction = (id: any, name: any) => {
    setOpen(true);
    setDeleteId(id);
    setName(name);
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteId(null);
  };

  React.useEffect(() => {
    (async () => {
      const resList = await ProfessionalsService.list(
        page + 1,
        20,
        search,
        filter,
        false,
        active
      );
      setData(resList.data);

      const newData: any = [];
      const resListAll = await ProfessionalsService.list(
        page + 1,
        260,
        search,
        filter,
        false,
        active
      );
      // Formatar dados e adicionar ao newData
      newData.push([
        "Data de Criação",
        "Data de Vencimento do Plano",
        "ID",
        "Imagem",
        "Nome",
        "E-mail",
        "WhatsApp",
        "CPF/CNPJ",
        "Título",
        "Cidade",
        "Estado",
        "Status do Plano",
      ]);
      resListAll.data.results.forEach((row: any) => {
        newData.push([
          moment(row.createdAt).format("DD/MM/YYYY HH:mm:ss").toString(),
          moment(row?.professionalsPlans[0]?.date_due)
            .format("DD/MM/YYYY HH:mm:ss")
            .toString(),
          row?.id,
          row?.picture,
          row?.name,
          row?.email,
          row?.whatsapp,
          row?.cpf_cnpj,
          row?.title,
          row?.clinicAddresses[0]?.city,
          row?.clinicAddresses[0]?.state,
          row?.status,
        ]);
      });
      setTimeout(async () => {
        setCsvExport(newData);
        Toast?.close();
      }, 6000);
    })();
  }, []);

  const handlerOnBlur = async (e: any) => {
    setSearch(e.target.value);
    const resList = await ProfessionalsService.list(
      page + 1,
      20,
      e.target.value,
      filter,
      false,
      active
    );
    setData(resList.data);
  };

  const getStatusLabel = (row: {
    professionalSpecialities: any;
    clinicAddresses: { city: any }[];
    formation: any;
    professionalsPlans: { isActive: any }[];
  }) => {
    if (
      !row?.professionalSpecialities ||
      !row?.clinicAddresses?.[0]?.city ||
      !row?.formation
    ) {
      return "Cadastro Incompleto";
    }
    return row?.professionalsPlans?.[0]?.isActive ? "Ativo" : "Inativo";
  };

  // Função para criar o arquivo Excel e iniciar o download
  const downloadExcel = () => {
    const ws = XLSX.utils.aoa_to_sheet(csvExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "backoffice-minhafono.xlsx");
  };

  return (
    <Grid container={true} spacing={6}>
      <Grid item={true} xs={12}>
        {active ? <></> : <></>}
        <Paper>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span>
              <InputLabel id="demo-multiple-checkbox-label">
                Pesquisar
              </InputLabel>
              <TextField
                inputProps={{
                  autoComplete: "off",
                }}
                type="text"
                autoComplete="off"
                label="Pesquisar"
                variant="outlined"
                onChange={handlerOnBlur}
              />
            </span>
            <span
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <InputLabel id="demo-multiple-checkbox-label">Filtro</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                value={personName}
                onChange={handleChange}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {names.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={personName.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>

              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Age"
                value={active}
                onChange={handleChangeStatus}
              >
                <MenuItem value={"findAll"}>Todos</MenuItem>
                <MenuItem value={"true"}>Ativo</MenuItem>
                <MenuItem value={"false"}>Inativo</MenuItem>
              </Select>

              <Button
                style={{ margin: "10px" }}
                variant="contained"
                color="primary"
                onClick={() => {
                  const status =
                    active === "true"
                      ? "Ativo"
                      : active === "false"
                      ? "Inativo"
                      : active === "findAll"
                      ? "Todos"
                      : "Todos";

                  exportUsersToExcel(status);
                }}
              >
                Download Excel
              </Button>
            </span>
          </div>

          <Dialog open={open} onClose={handleClose}>
            <DialogTitle id="alert-dialog-title">{`Deseja excluir ${name}?`}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Tem certeza que deseja excluir?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleRemove} color="primary">
                Sim
              </Button>
              <Button onClick={handleClose} color="primary" autoFocus={true}>
                Não
              </Button>
            </DialogActions>
          </Dialog>

          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <TableHead>
                <TableCell style={{ width: "70px" }}>Nome / Usuário</TableCell>
                <TableCell style={{ width: "70px" }}>Especialidade</TableCell>
                <TableCell style={{ width: "150px" }} align="left">
                  Email
                </TableCell>
                <TableCell>Área</TableCell>
                <TableCell>Cidade</TableCell>
                <TableCell>Tipos de atendimento</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="center">Criação / Atualização</TableCell>
                <TableCell align="center">Ações</TableCell>
              </TableHead>
              <TableBody>
                {data?.results?.map((row: any, index: number) => (
                  <TableRow hover key={`${row.id}-${index}`} tabIndex={1}>
                    <TableCell>
                      <Typography>
                        {row?.name}
                        <br />
                        CPF/CNPJ: {row?.cpf_cnpj ? row?.cpf_cnpj : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {row?.professionalSpecialities?.map((s: any) => (
                          <Chip label={`${s?.speciality?.name}`} />
                        ))}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{row?.email || "-"}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{row?.formation || "-"}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {active ? row?.clinicAddresses?.[0]?.city || "-" : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {row?.professionalAttendenceMethods?.map((p: any) => (
                          <Chip label={`${p?.attendenceMethod?.name}`} />
                        ))}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Chip
                        style={{
                          background:
                            row?.status &&
                            row?.formation &&
                            row?.clinicAddresses?.[0]?.city
                              ? "green"
                              : "red",
                          color: "white",
                        }}
                        label={
                          row?.status &&
                          row?.formation &&
                          row?.clinicAddresses?.[0]?.city
                            ? "Ativo"
                            : "Inativo"
                        }
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Typography>
                        <MFDateFormat date={row.createdAt} />
                        <br />
                        {row.updatedAt && <MFDateFormat date={row.updatedAt} />}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        aria-label="edit"
                        component={NavLink}
                        exact
                        to={`/professionals/${row.id}/edit`}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleRemoveAction(row.id, row.name)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[20]}
            component="div"
            count={data?.totalResults}
            rowsPerPage={20}
            page={page}
            onChangePage={handleChangePage}
          />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={goToLastPage}
              variant="contained"
              color="primary"
              disabled={!data?.totalResults}
            >
              Última Página
            </Button>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}

export const ProfessionalIndexPage = () => {
  return (
    <ProtectedPage
      title="Profissionais"
      breadcrumbs={[{ name: "Profissionais", to: "/professionals" }]}
      action={
        <Button
          color="primary"
          component={NavLink}
          exact={true}
          startIcon={<AddIcon />}
          to="/professionals/new"
          variant="contained"
        >
          Cadastrar Profissional
        </Button>
      }
    >
      <Grid container={true} spacing={6}>
        <Grid item={true} xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </ProtectedPage>
  );
};
