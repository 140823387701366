import { PrintAndPracticeService } from "../../services";
import { InsurancesForm, ProtectedPage } from "../../components";
import { useParams } from "react-router-dom";
import React from "react";
import { AppsForm } from "../../components/AppsForm";
import { PrintForm } from "../../components/PrintForm";

export const PrintEditFormPage = () => {
  const [data, setData] = React.useState([]);
  const { id } = useParams<{ id: any }>();

  React.useEffect(() => {
    (async () => {
      const resList = await PrintAndPracticeService.getById(id);
      setData(resList.data);
    })();
  }, []);

  const handleSubmit = async (form: any) => {
    await PrintAndPracticeService.update(id, form);
  };

  return (
    <ProtectedPage
      title="Editar Imprimir"
      breadcrumbs={[
        { name: "imprimir", to: "/imprimir" },
        { name: "Editar imprimir", to: "/imprimir/:id/edit" },
      ]}
    >
      <PrintForm
        data={data}
        buttonName="Salvar"
        formTitle="Editar imprimir"
        handleSubmit={handleSubmit}
        resetFields={false}
        successMessage="imprimir Atualizado com Sucesso!"
      />
    </ProtectedPage>
  );
};
