import { api } from "./BackendService";

export const create = (data: any) =>
  api.post("backoffice/applications", data);
export const deleteById = (id: number) =>
  api.delete(`backoffice/applications/${id}`);
export const update = (id: number, data: any) =>
  api.put(`backoffice/applications/${id}`, data);
export const getById = (id: number) => api.get(`backoffice/applications/${id}`);
export const list = () => api.get('backoffice/applications');
